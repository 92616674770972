import * as React from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import Title from '../Title';


const data_init = [
  {'time': "00:00",'amount':3},
  {'time': "01:00",'amount':10},
  {'time': "02:00",'amount':24},
  {'time': "03:00",'amount':27},
  {'time': "04:00",'amount':23},
  {'time': "05:00",'amount':17},
  {'time': "06:00",'amount':8},
  {'time': "07:00",'amount':0},
  {'time': "08:00",'amount':0},
  {'time': "09:00",'amount':0},
  {'time': "10:00",'amount':3},
  {'time': "11:00",'amount':0},
  {'time': "12:00",'amount':0},
  {'time': "13:00",'amount':0},
  {'time': "14:00",'amount':0},
  {'time': "15:00",'amount':0},
  {'time': "16:00",'amount':0},
  {'time': "17:00",'amount':0},
  {'time': "18:00",'amount':1},
  {'time': "19:00",'amount':3},
  {'time': "20:00",'amount':5},
  {'time': "21:00",'amount':9},
  {'time': "22:00",'amount':4},
  {'time': "23:00",'amount':10},
];

const read = [];

function alreadyRead(time) {
  for (let i = 0; i < read.length; i++) {
    if (read[i] === time) {
      return true;
    }
  }
  return false;
}

export default function Chart(props) {
  const theme = useTheme();

  const [data, setData] = React.useState(data_init);

  useEffect(() => {
    setTimeout(() => {
      for (let i = 0; i < props.intrusions.length; i++) {
        if (!alreadyRead(props.intrusions[i]['time'])){

          var IntruData= new Date(props.intrusions[i]['time'])
          var Intrudia=IntruData.getDate()
          var Intrumes=IntruData.getMonth()
          var Intruano=IntruData.getFullYear()
          var Intruhora=IntruData.getHours()


          var currentdate = new Date(); 
          var dia=currentdate.getDate()
          var mes=currentdate.getMonth()
          var ano=currentdate.getFullYear()

          if(dia==Intrudia && mes==Intrumes && ano==Intruano){
            data[Intruhora] = {'time': Intruhora+':00', 'amount': data[Intruhora]['amount']+1}
            read.push(props.intrusions[i]['time'])
          }

        }
      }
    }, 1000);
  });

  return (
    <React.Fragment>
      <Title>Today</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis
            dataKey="time"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Intrusions
            </Label>
          </YAxis>
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="amount"
            stroke={theme.palette.primary.main}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}