import * as React from 'react';
import Title from '../Title';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { getSites, addSite, removeSite, getOwners } from '../Requests';

// Generate Order Data
var rows = [
    { id:0, BuildingName: 'Jon', Location: 'Aveiro', Owner: 'joao', Cameras: ['camera_1', 'camera_2'] },
    { id:1, BuildingName: 'Cersei', Location: 'Aveiro', Owner: 'luis', Cameras: ['camera_1', 'camera_2'] },
    { id:2, BuildingName: 'Jaime', Location: 'Aveiro', Owner: 'toto', Cameras: ['camera_1', 'camera_2'] },
    { id:3, BuildingName: 'Arya', Location: 'Aveiro', Owner: 'the weeknd', },
    { id:4, BuildingName: 'Daenerys', Location: 'Aveiro', Owner: 'bla bla', Cameras: ['camera_1', 'camera_2'] },
    { id:5, BuildingName: 'null', Location: 'Aveiro', Owner: 'boys', Cameras: ['camera_1', 'camera_2'] },
    { id:6, BuildingName: 'Ferrara', Location: 'Aveiro', Owner: 'anita' },
    { id:7, BuildingName: 'Rossini', Location: 'Aveiro', Owner: 'xoxo' },
    { id:8, BuildingName: 'Harvey', Location: 'Aveiro', Owner: 'rosalia',Cameras: ['camera_1', 'camera_2'] },
    { id:9, BuildingName: 'Harvey', Location: 'Aveiro', Owner: 'x-tense' },
    { id:10, BuildingName: 'Harvey', Location: 'Aveiro', Owner: 'x-tense',Cameras: ['camera_1', 'camera_2'] },

  ];

  const columns = [
    { field: 'BuildingName', headerName: 'Building Name', width: 250 },
    { field: 'Location', headerName: 'Location', width: 250 },
    { field: 'Owner', headerName: 'Owner', width: 250 },
    { field: 'Cameras', headerName: 'Cameras', width: 300 },
  ];



export default function PropertiesTable() {

  const [site_name, setSiteName] = React.useState();
  const [site_location, setSiteLocation] = React.useState();
  const [owner, setOwner] = React.useState();
  // const [cameras_array, setCameras] = React.useState();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [removeID, setRemoveID] = React.useState(-1);

  const handleCellClick = (cell_id) => {
    setRemoveID(cell_id);
  }

  const removeClicked = () => {
    console.log(removeID);
    if (removeID !== -1) {

      for(var i = 0; i < sites.length; i++) {
        if (sites[i].id === removeID) {
          let tempArray = sites.slice();
          tempArray.splice(i, 1);
          setSites(tempArray);
          console.log(sites);
          break;
        }
      }

      removeSite(removeID);
      setRemoveID(-1);
    }else{
      alert("Please select a site to remove");
    }
  }

  const handleOk = () => {
    let id = sites[sites.length - 1].id + 1;

    //addSite(id,site_name, site_location, owner,[]);
    //! update table with ids do not forget
    let tempArray = sites.slice();
    tempArray.push({id:id, BuildingName: site_name, Location: site_location, Owner: owner, Cameras: []});
    setSites(tempArray);

    setOpen(false);
  };

  const [sites, setSites] = React.useState(rows);

  const [didLoad, setDidLoad] = React.useState(false);  

  const [clients, setClients] = React.useState([{id:1,name:"nuno", description: 'House | Office'},{id:2,name:"vasco", description: 'Warehouse | Park'}]);

  useEffect(() => {
    if (!didLoad) {
      getSites().then((response) => {
        var tmp = response.data.lista;
        //{ id:0, BuildingName: 'Jon', Location: 'Aveiro', Owner: 'joao', Cameras: ['camera_1', 'camera_2'] },
        var tmp2 = [];
        for (var i = 0; i < tmp.length; i++) {
          tmp2.push({id:tmp[i][0], BuildingName: tmp[i][1], Location: tmp[i][2], Owner: tmp[i][3], Cameras: tmp[i][4]});
        }

        setSites(tmp2); 
        setDidLoad(true);
      });


      getOwners().then((response) => {
        var tmp = response.data.lista;

        var tmp2 = [];
        for (var i = 0; i < tmp.length; i++) {
          tmp2.push({id:tmp[i][0],name:tmp[i][1]});

        }

        setClients(tmp2);
        
        setDidLoad(true);
      });

    }
  });

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{marginBottom: '1vw'}}>
        <Grid item xs={9}>
          <Title>Properties List</Title>
        </Grid>
        <Grid item xs={3}>
          <div style={{float: 'right'}}>
          <Button variant="contained" style={{marginRight:'1vw'}} onClick={handleClickOpen}>Add</Button>
          <Button variant="contained" color="error" onClick={removeClicked} >Remove</Button>
          </div>
        </Grid>
      </Grid>
      

      <div style={{ height: '34.5vw', width: '100%' }}>
      <DataGrid
        rows={sites} //! rows
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        onCellClick={e => handleCellClick(e.id)}
      />
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Site</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="site_name"
            label="Building Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setSiteName(e.target.value)}  
          />
          <TextField
            autoFocus
            margin="dense"
            id="site_location"
            label="Location"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setSiteLocation(e.target.value)}  
          />
          
          <FormControl fullWidth style={{marginTop: '2vw'}}>
            <InputLabel id="demo-simple-select-label">Owner</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={owner}
              label="Owner"
              onChange={e => setOwner(e.target.value)}
            >
              {clients.map((client) => (
                <MenuItem value={client.name}>{client.name}</MenuItem>
              ))}

            </Select>
          </FormControl>
          
          {/* <TextField
            autoFocus
            margin="dense"
            id="cameras"
            label="Camera_Array"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setCameras(e.target.value)} 
          /> */}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
}