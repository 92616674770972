import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import { requestNotifications } from './Requests';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import LogoutIcon from '@mui/icons-material/Logout';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );

  const data_init = [
    {
      camera: "Camera 2", 
      message: "Intrusion detected.", 
      recording: "748924015367815174.mp4", 
      time: "Wed Oct 19 20:04:57 2022", 
      owner: "nuno@ua.pt", 
      location: "Aveiro",
      url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
    },
    {
      camera: "Camera 1", 
      message: "Intrusion detected.", 
      recording: "2376468214.mp4", 
      time: "Wed Oct 19 20:04:57 2022", 
      owner: "vasco@ua.pt", 
      location: "Lisbon",
      url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
    }
  ]

export default function Sidebar(props) {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
      setOpen(!open);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [notifications, setNotifications] = React.useState(data_init);

    const [didLoad, setDidLoad] = React.useState(false);

    useEffect(() => {

      if (!didLoad) {
        console.log("DIDLOAD");
        setDidLoad(true);
        if(localStorage.getItem('token') !== "true"){
          navigate('/');
        }
      }

      setTimeout(() => {
        requestNotifications().then((response) => {
          var res=JSON.parse(response.data)
          var tmp = []
          for (var key in res) {//key is the owner
            for(var i=0;i<res[key].length;i++){
              if(!(res[key][i] in notifications)){
                tmp.push(res[key][i])
                tmp.sort ( function (date1, date2){
                  return new Date(date2.time) - new Date(date1.time)
                });
                setNotifications(tmp)
              }
            } 
          }

          //setNotifications(tmp); 
          // [{camera: "Camera 2" message: "Intrusion detected." recording: "748924015367815174.gif" time: "Wed Oct 19 20:04:57 2022"}, .... ]
          // { "message": "Intrusion detected.", "camera": 1, "recording": "camera1_04_December_2022_at_07_12_22PM", "url": "https://www.youtube.com/watch?v=dQw4w9WgXcQ", "time": 'Sun Dec  4 21:12:26 2022'}
        });
      }, 1000);
    });

    const navigate = useNavigate();

    return (
        <React.Fragment>
        <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {props.page_name}
          </Typography>
          <IconButton color="inherit" onClick={handleMenu} >
            <Badge badgeContent={notifications.length} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {notifications.map((notification) => {
                  return (
                    <div>
                      <MenuItem 
                        onClick={handleClose}
                      >
                        <Grid container spacing={0} width={'50vw'} >
                          <Grid item xs={6}>
                            <div style={{ color: 'red' }}>{notification.message} </div>
                          </Grid>
                          <Grid item xs={6}>
                            {'Email sent to: '+notification.owner}
                          </Grid>
                          <Grid item xs={6}>
                            {'Camera: '+notification.camera}
                          </Grid>
                          <Grid item xs={6}>
                            {'Location: '+notification.location}
                          </Grid>
                          <Grid item xs={6}>
                            {notification.time}
                          </Grid>
                          <Grid item xs={6}>
                            <a href={notification.url} target="_blank" >{notification.url}</a>
                          </Grid>
                        </Grid>
                      </MenuItem>
                      <Divider />
                    </div>
                  )}
                )}
                
            </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
            <ListItemButton onClick={() => navigate('/dashboard')}>
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/intrusions')}>
            <ListItemIcon>
                <WarningIcon />
            </ListItemIcon>
            <ListItemText primary="Intrusions" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/properties_table')}>
            <ListItemIcon>
                <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Properties" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/clients_list')}>
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Clients" />
            </ListItemButton>
          <Divider sx={{ my: 1 }} />
            <ListItemButton onClick={() => 
              {
                localStorage.setItem('token', null);
                navigate('/')
              }}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
            </ListItemButton>
        </List>
      </Drawer>
      </React.Fragment>
    );
  }