import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from '../Title';
import { useNavigate } from 'react-router-dom';



export default function TotalIntrusionsDaily(props) {

  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];

  const navigate = useNavigate();

  //! ATENÇAO A LISTA NAO É LIMPA TODOS OS DIAS linha 24 {props.intrusions.length} 


  function today() {
    let count = 0;

    for (let i = 0; i < props.intrusions.length; i++) {
      var IntruData= new Date(props.intrusions[i]['time'])
      var Intrudia=IntruData.getDate()
      var Intrumes=IntruData.getMonth()
      var Intruano=IntruData.getFullYear()

      var dia=date.getDate()
      var mes=date.getMonth()
      var ano=date.getFullYear()

      if(dia==Intrudia && mes==Intrumes && ano==Intruano){
        count++;
      }
    }

    return count;
  };

  return (
    <React.Fragment>
      <Title>Total Intrusions Today</Title>
      <Typography component="p" variant="h4">
        {88} 
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        
        on {date.getDate()} {monthNames[date.getMonth()]}, {date.getFullYear()}
      </Typography>
      <div>
        <Link color="primary" href="" onClick={() => navigate('/intrusions')}>
          View all the intrusions
        </Link>
      </div>
    </React.Fragment>
  );
}