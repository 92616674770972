import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Sidebar from '../Sidebar';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { getOwners, removeOwner, addOwner, getSitesOwner } from '../Requests';


const mdTheme = createTheme();

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function ClientsListPage() {

  const [checkboxState, setCheckboxState] = React.useState([false,false]);

  const [clients, setClients] = React.useState([{id:1,name:"nuno", description: 'House | Office'},{id:2,name:"vasco", description: 'Warehouse | Park'}]);

  const [didLoad, setDidLoad] = React.useState(false);  

  const [didLoad2, setDidLoad2] = React.useState(false);  

  const [OwnerName,setOwnerName] = React.useState();


  const handleOk = () => {

    let newCheckboxState = [...checkboxState];
    newCheckboxState.push(false);
    setCheckboxState(newCheckboxState);

    let newClients = [...clients];
    let newId=clients[clients.length - 1].id + 1;
    newClients.push({id:newId, name:OwnerName});
    setClients(newClients);

    addOwner(OwnerName);

    setDidLoad2(false);
    setOpen(false);
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeClicked = () => {

    var found=false;
    for (let i = 0; i < checkboxState.length; i++) {
      if (checkboxState[i]) {

        let newCheckboxState = [...checkboxState];
        newCheckboxState.splice(i, 1);
        setCheckboxState(newCheckboxState);

        let newClients = [...clients];
        newClients.splice(i, 1);
        setClients(newClients);

        removeOwner(clients[i].id);
        found=true;
      }
    }

    if (!found) {
      alert("Please select an owner to remove");
    }
  }


  useEffect(() => {

    if (!didLoad2) {
      for (let i = 0; i < clients.length; i++) {
        getSitesOwner(clients[i].name).then((res) => {

          let newClients = [...clients];
    
          var str = "|";
          for (var j = 0; j < res.data.lista.length; j++) {
            str=str+res.data.lista[j][1]+"|";
          }

          if (res.data.lista.length===0) {
            str="No sites";
          }
    
          newClients[i].description= str;
    
          setClients(newClients);
          setDidLoad2(true);
          
        });
      }    
    }  
  }, [clients,didLoad2]);

  useEffect(() => {
    if (!didLoad) {
      getOwners().then((response) => {
        var tmp = response.data.lista;

        var tmp2 = [];
        var tmp3 = [];
        for (var i = 0; i < tmp.length; i++) {
          tmp2.push(false);
          tmp3.push({id:tmp[i][0],name:tmp[i][1], description: 'Empty'});

        }

        setClients(tmp3);

        setCheckboxState(tmp2); 
        
        setDidLoad(true);

      });

    }  

  });

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar page_name='Clients'/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <div style={{marginBottom:'2vw', float: 'right'}}>
          <Button variant="contained" style={{marginRight:'1vw'}} onClick={handleClickOpen} >Add</Button>
          <Button variant="contained" color="error" onClick={removeClicked} >Remove</Button>
          </div>
          <Grid container spacing={2}>

            {clients.map((client,idx) => {
              return (
              <Grid item xs={4}>
                <Paper>
                <React.Fragment>
                  <Grid container spacing={2} style={{marginBottom: '1vw'}}>
                      <Grid item xs={4}>
                          <Avatar alt="ERROR" src={'/clients/joao.webp'} sx={{ width: 100, height: 100 }} style={{marginBottom: '1vw', marginLeft:'1vw'}}/>
                      </Grid>
                      <Grid item xs={6}>
                          
                          <h3>{client.name}</h3>
                          <p>{client.description}</p>

                      </Grid>
                      <Grid item xs={1}>
                          <Checkbox {...label} 
                          checked={checkboxState[idx]}
                          onChange={() => {

                            let newCheckboxState = [...checkboxState];

                            for(let i=0; i<newCheckboxState.length; i++){
                              newCheckboxState[i] = false;
                              if(i===idx){
                                newCheckboxState[i] = true;
                              }
                            }
                            setCheckboxState(newCheckboxState);
                          }}
                          />
                      </Grid>
                  </Grid>
                </React.Fragment>
                </Paper>
              </Grid>
              )}
            )}
            
          </Grid>
          </Container>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Owner</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="owner_name"
            label="Owner email"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setOwnerName(e.target.value)}  
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogActions>
      </Dialog>

        </Box>
      </Box>
    </ThemeProvider>
  );
}

