import * as React from 'react';
import { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import TotalIntrusionsDaily from './TotalIntrusionsDaily';
import RecentIntrusions from './RecentIntrusions';
import Sidebar from '../Sidebar';
import { requestNotifications } from '../Requests';

const mdTheme = createTheme();

function DashboardContent() {

  const [notifications, setNotifications] = React.useState([]);

  useEffect(() => {
    setTimeout(() => {
      requestNotifications().then((response) => {
        var res=JSON.parse(response.data)
        var tmp = []
        for (var key in res) {//key is the owner
          for(var i=0;i<res[key].length;i++){
            if(!(res[key][i] in notifications)){
              tmp.push(res[key][i])
              tmp.sort ( function (date1, date2){
                return new Date(date2.time) - new Date(date1.time)
              });
              
              setNotifications(tmp)
            }
          } 
        }
      });
    }, 1000);
  });

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar page_name='Dashboard'/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Chart intrusions={notifications} />
                </Paper>
              </Grid>
              {/* Recent TotalIntrusionsDaily */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <TotalIntrusionsDaily intrusions={notifications}/>
                </Paper>
              </Grid>
              {/* Recent RecentIntrusions */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <RecentIntrusions intrusions={notifications}/>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}