import { Route, Routes} from 'react-router-dom';
import Dashboard from './screens/dashboard/Dashboard';
import IntrusionsPage from './screens/intrusions/IntrusionsPage';
import PropertiesTablePage from './screens/properties/PropertiesTablePage';
import ClientsListPage from './screens/clients/ClientsListPage';
import SignInSide from './screens/SignInSide';

function App() {

    document.title = 'SecCom';

    return (
      <div>
      <Routes>
          <Route index element={<SignInSide />} />
          <Route path="/" element={<SignInSide />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/intrusions" element={<IntrusionsPage />} />
          <Route path="/properties_table" element={<PropertiesTablePage />} />
          <Route path="/clients_list" element={<ClientsListPage />} />
      </Routes>
    </div>
    );
}

export default App;
