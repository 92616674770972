import axios from "axios";

const notifications='https://0hr7095u1m.execute-api.eu-west-1.amazonaws.com'
const sitesAPI='https://481t6xb8z7.execute-api.eu-west-1.amazonaws.com'

export function requestNotifications() {

    let myPromise = new Promise(function(myResolve) {

        axios.get(notifications+'/notifications')
        .then((response) => {
            myResolve(response);
        })
        .catch((error) => {
            console.log(error)
        })

    });

    return myPromise;
}


export function getSites() {

    let myPromise = new Promise(function(myResolve) {

        axios.get(sitesAPI+'/getSites')
        .then((response) => {
            myResolve(response);
        })
        .catch((error) => {
            console.log(error)
        })

    });

    return myPromise;
}

export function addSite(id,buildingName, location, owner,cameras_list) {

    axios.post(sitesAPI+'/addSite', {
        ID:id,
        BuildingName: buildingName,
        Location: location,
        Owner: owner,
        Cameras: cameras_list
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
}

export function removeSite(id) {
    
    axios.post(sitesAPI+'/removeSite', {
        ID: id
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

}

export function getOwners() {

  let myPromise = new Promise(function(myResolve) {

      axios.get(sitesAPI+'/getOwners')
      .then((response) => {
          myResolve(response);
      })
      .catch((error) => {
          console.log(error)
      })

  });

  return myPromise;
}

export function removeOwner(id) {
    
  axios.post(sitesAPI+'/removeOwner', {
      ID: id
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

}

export function addOwner(name) {

  axios.post(sitesAPI+'/addOwner', {
      Name:name
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function getSitesOwner(owner) {

  let myPromise = new Promise(function(myResolve) {

      axios.get(sitesAPI+'/getSitesOwner/'+owner)
      .then((response) => {
          myResolve(response);
      })
      .catch((error) => {
          console.log(error)
      })

  });

  return myPromise;
}


export function get_owner_camera(id) {

  let myPromise = new Promise(function(myResolve) {

      axios.get(sitesAPI+'/getOwnerCamera/'+id)
      .then((response) => {
          myResolve(response);
      })
      .catch((error) => {
          console.log(error)
      })

  });

  return myPromise;
}