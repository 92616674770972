import * as React from 'react';
import { useEffect } from 'react';
import Title from '../Title';
import { DataGrid } from '@mui/x-data-grid';
import { requestNotifications, get_owner_camera } from '../Requests';


  const columns = [
    { field: 'camera', headerName: 'Camera ID', width: 100 },
    { field: 'location_building', headerName: 'Location/Building', width: 200 },
    { field: 'time', headerName: 'Date', width: 200 },
    { field: 'owner', headerName: 'Owner',width: 200 },
    { field: 'url', headerName: 'URL (click)', width: 400 },
  ];



function handleCellClick(value) {
  if(value.includes("https://")){//!ATENÇAO AO LINK DO S3
    window.open(value, '_blank');
  }
}

const data_init = [
  {
    id: 1,
    camera: "Camera 2",
    location_building: "Aveiro/House",
    recording: "748924015367815174.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "nuno",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 2,
    camera: "Camera 1",
    location_building: "Lisbon/Office",
    recording: "2376468214.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "vasco",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 3,
    camera: "Camera 3",
    location_building: "Lisbon/warehouse",
    recording: "2376468214.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "vasco",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 4,
    camera: "Camera 4",
    location_building: "Aveiro/House",
    recording: "2376468214.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "vasco",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 5,
    camera: "Camera 5",
    location_building: "Aveiro/House",
    recording: "2376468214.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "vasco",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 6,
    camera: "Camera 6",
    location_building: "Aveiro/House",
    recording: "2376468214.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "vasco",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 7,
    camera: "Camera 7",
    location_building: "Aveiro/House",
    recording: "2376468214.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "vasco",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 8,
    camera: "Camera 8",
    location_building: "Aveiro/House",
    recording: "2376468214.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "nuno",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 9,
    camera: "Camera 9",
    location_building: "Aveiro/House",
    recording: "2376468214.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "nuno",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 10,
    camera: "Camera 10",
    location_building: "Aveiro/House",
    recording: "2376468214.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "nuno",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 11,
    camera: "Camera 11",
    location_building: "Aveiro/House",
    recording: "748924015367815174.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "nuno",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 12,
    camera: "Camera 12",
    location_building: "Aveiro/House",
    recording: "748924015367815174.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "nuno",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  },
  {
    id: 13,
    camera: "Camera 13",
    location_building: "Aveiro/House",
    recording: "748924015367815174.mp4",
    time: "Wed Oct 19 20:04:57 2022",
    owner: "nuno",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  }
]

export default function IntrusionsTable() {

  const [data, setData] = React.useState(data_init);

  useEffect(() => {
    setTimeout(() => {
      requestNotifications().then((response) => {
        var res=JSON.parse(response.data)
        var tmp = []
        var counter=0;
        for (var key in res) {//key is the owner
          for(var i=0;i<res[key].length;i++){
            if(!(res[key][i] in data)){
              tmp.push({'id': counter, 'camera':res[key][i].camera, 'location_building':res[key][i].location+'/'+res[key][i].building, 'time':res[key][i].time, 'owner':res[key][i].owner ,'url': res[key][i].url })
              counter++;
            }
          } 
        }

        tmp.sort ( function (date1, date2){
          return new Date(date2.time) - new Date(date1.time)
        });
        setData(tmp)
      });
    }, 1000);

  });

  return (
    <React.Fragment>
      <Title>Recent Intrusions</Title>
      <div style={{ height: '35vw', width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        onCellClick={e => handleCellClick(e.value)}
      />
      </div>
    </React.Fragment>
  );
}