import * as React from 'react';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import { useNavigate } from 'react-router-dom';





const data_init = [
  {'message': null, 'url': 'https://www.youtube.com/watch?v=dQw4w9WgXcQ', 'location': 'Lisbon','building': 'Warehouse' ,'camera': 'camera#2', 'owner': 'Nuno', 'time': 'Sun Dec  4 21:12:26 2022'},
  {'message': null, 'url': 'https://www.youtube.com/watch?v=dQw4w9WgXcQ', 'location': 'Aveiro','building': 'House' ,'camera': 'camera#1', 'owner': 'Nuno', 'time': 'Sun Dec  4 21:12:26 2022'},
  {'message': null, 'url': 'https://www.youtube.com/watch?v=dQw4w9WgXcQ', 'location': 'Lisbon','building': 'Office' ,'camera': 'camera#3', 'owner': 'Vasco', 'time': 'Sun Dec  4 21:12:26 2022'},
  {'message': null, 'url': 'https://www.youtube.com/watch?v=dQw4w9WgXcQ', 'location': 'Lisbon','building': 'warehouse' ,'camera': 'camera#5', 'owner': 'Vasco', 'time': 'Sun Dec  4 21:12:26 2022'},
  {'message': null, 'url': 'https://www.youtube.com/watch?v=dQw4w9WgXcQ', 'location': 'Aveiro','building': 'House' ,'camera': 'camera#2', 'owner': 'Nuno', 'time': 'Sun Dec  4 21:12:26 2022'},
];


export default function RecentIntrusions(props) {
  const [data, setData] = React.useState(data_init);

  useEffect(() => {
    setTimeout(() => {
      var tmp=props.intrusions
      for (let i = 0; i < 5; i++) {
        data[i] = {'url': tmp[i].url, 'location': tmp[i].location, 'building': tmp[i].building , 'camera':tmp[i].camera, 'owner':tmp[i].owner, 'time':tmp[i].time};
      }

    }, 1000);
  });

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Title>Recent Intrusions</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Camera</TableCell>
            <TableCell>Location/Building</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Owner</TableCell>
            <TableCell align="right">URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(function(row) {
            return (row.url != '　') ?
            <TableRow>
              <TableCell>{row.camera}</TableCell>
              <TableCell>{row.location+'/'+row.building}</TableCell>
              <TableCell>{row.time}</TableCell>
              <TableCell>{row.owner}</TableCell>
              
              <TableCell align="right"><a href={row.url} target="_blank" >Link To Video</a></TableCell>
            </TableRow>
            :
            <TableRow>
              <TableCell>{row.camera}</TableCell>
              <TableCell>{row.location}</TableCell>
              <TableCell>{row.time}</TableCell>
              <TableCell>{row.owner}</TableCell>
              <TableCell align="right">　</TableCell>
            </TableRow>

          })}
        </TableBody>
      </Table>
      <Link color="primary" href="" onClick={() => navigate('/intrusions')} sx={{ mt: 3 }}>
        See more intrusions
      </Link>
    </React.Fragment>
  );
}